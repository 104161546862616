import React from 'react';
import loadable from '@loadable/component';
import ContentData from 'content/aboutUs-notJustOffice.json';
import { Link } from 'gatsby';
import StaticShape from 'components/_shared/AloneShapes/StaticShape/StaticShape';
import { colors } from 'utils/variables';
import {
  StyledWrapper,
  StyledContainer,
  StyledImage,
  StyledImage2,
  StyledContent,
  StyledTitle,
  StyledDescription,
  StyledButton,
  StyledShape1,
  StyledShape3,
} from './NotJustOffice.styled';

const AnimatedShape =  loadable(() => import('components/_shared/AloneShapes/AnimatedShape/AnimatedShape'));

const NotJustOffice = () => (
  <StyledWrapper>
    <StyledContainer>
      <StyledShape1>
        <AnimatedShape
          name="NotJustOffice-1"
          originalWidth={365}
          originalHeight={505}
          paths={[
            ' M 2.262 23.245 C 0.962 22.195 0.213 20.696 0.112 19.246 C -0.053 16.867 0.013 18.269 0.013 15.997 C 0.013 14.741 1.859 9.27 2.253 8.125 C 4.044 2.919 7.708 0.003 11.258 0.003 C 12.658 0.003 11.793 -0.004 13.307 0.003 C 13.592 0.004 14.358 0.081 14.841 0.253 C 16.838 0.963 18.324 3.327 18.247 6.251 C 18.192 8.349 15.856 10.399 15.706 12.624 C 15.616 13.968 17.074 15.835 17.556 17.247 C 17.95 18.404 17.706 19.671 17.556 20.646 C 17.368 21.863 16.846 22.424 16.256 23.245 C 15.498 24.301 13.307 25.235 12.258 25.244 C 10.738 25.257 8.739 25.244 7.86 25.244 C 6.1 25.244 3.706 24.411 2.262 23.245 Z ',
            ' M 1.449 3.13 C 2.208 1.331 3.291 0.295 4.339 0.155 C 6.059 -0.073 5.046 0.018 6.688 0.018 C 7.595 0.018 11.55 2.572 12.377 3.117 C 16.14 5.595 18.248 10.665 18.248 15.576 C 18.248 17.513 18.253 16.316 18.248 18.411 C 18.247 18.806 18.191 19.865 18.067 20.534 C 17.554 23.297 15.845 25.353 13.732 25.246 C 12.215 25.17 10.734 21.938 9.126 21.73 C 8.154 21.606 6.805 23.623 5.784 24.29 C 4.948 24.835 4.032 24.498 3.327 24.29 C 2.448 24.03 2.042 23.308 1.449 22.491 C 0.686 21.443 0.011 18.411 0.004 16.96 C -0.005 14.857 0.004 12.091 0.004 10.875 C 0.004 8.44 0.606 5.128 1.449 3.13 Z ',
            ' M 17.491 22.12 C 16.701 23.919 15.573 24.955 14.482 25.095 C 12.692 25.323 13.747 25.232 12.037 25.232 C 11.092 25.232 6.976 22.678 6.114 22.133 C 2.197 19.655 0.002 14.585 0.002 9.674 C 0.002 7.737 -0.003 8.934 0.002 6.839 C 0.003 6.444 0.061 5.385 0.19 4.716 C 0.725 1.953 2.504 -0.103 4.704 0.004 C 6.283 0.08 7.825 3.312 9.499 3.52 C 10.511 3.644 11.916 1.627 12.978 0.96 C 13.849 0.415 14.802 0.752 15.536 0.96 C 16.452 1.22 16.874 1.942 17.491 2.759 C 18.286 3.807 18.989 6.839 18.996 8.29 C 19.005 10.393 18.996 13.159 18.996 14.375 C 18.996 16.81 18.369 20.122 17.491 22.12 Z ',
          ]}
        />
      </StyledShape1>
      <StyledShape3>
        <StaticShape
          name="NotJustOffice-3"
          originalWidth={528}
          originalHeight={404}
          fill={colors.peachMilk}
          path="M 0.1 0 L 26.3 0 C 26.355 0 26.4 0.045 26.4 0.1 L 26.4 20.1 C 26.4 20.155 26.355 20.2 26.3 20.2 L 0.1 20.2 C 0.045 20.2 0 20.155 0 20.1 L 0 0.1 C 0 0.045 0.045 0 0.1 0 Z"
        />
      </StyledShape3>
      <StyledContent>
        <StyledTitle dangerouslySetInnerHTML={{ __html: ContentData.title }} />
        <StyledDescription dangerouslySetInnerHTML={{ __html: ContentData.description }} />
        <StyledButton as={Link} dangerouslySetInnerHTML={{ __html: ContentData.button.text }} to={ContentData.button.link} />
      </StyledContent>
      <StyledImage src="aboutUs-notJustOffice-1.jpg" />
      <StyledImage2 src="aboutUs-notJustOffice-2.jpg" />
    </StyledContainer>
  </StyledWrapper>
);

export default NotJustOffice;
