import React from 'react';
import ContentData from 'content/aboutUs-identity.json';
import { StyledWrapper, StyledContainer, StyledImage, StyledContent } from './Identity.styled';

const Identity = () => (
  <StyledWrapper>
    <StyledContainer>
      <StyledContent dangerouslySetInnerHTML={{ __html: ContentData.text }} />
      <StyledImage src="aboutUs-identity-1.jpg" />
    </StyledContainer>
  </StyledWrapper>
);

export default Identity;
