import styled from 'styled-components';
import Image from 'components/_shared/Image/Image';
import { fontWeight, colors, breakPoints } from 'utils/variables';
import Button from 'components/_shared/Button/Button';
import Container from 'components/_shared/Container/Container';

export const StyledWrapper = styled.div`
  position: relative;
`;

export const StyledContainer = styled(Container)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding-bottom: 4rem;

  @media (min-width: ${breakPoints.tablet}) {
    flex-direction: column;
    padding-bottom: 0;
  }

  @media (min-width: ${breakPoints.desktop}) {
    flex-direction: row;
    padding-bottom: 4rem;
  }
`;

export const StyledImage = styled(Image)`
  position: relative;
  right: 0;
  top: 2rem;
  z-index: 10;
  width: 100%;
  height: auto;
  max-width: 50rem;
  max-height: 30rem;
  box-shadow: 0.4rem 0.4rem 0 0 ${colors.butter};

  @media (min-width: ${breakPoints.desktop}) {
    position: absolute !important;
    right: 0;
    top: 0;
  }
`;

export const StyledImage2 = styled(Image)`
  position: relative;
  display: none;
  right: 0;
  top: 2rem;
  width: 100%;
  height: auto;
  max-width: 50rem;
  max-height: 30rem;
  box-shadow: 0.4rem 0.4rem 0 0 ${colors.butter};

  @media (min-width: ${breakPoints.tablet}) {
    display: block;
    right: -10rem;
    top: -5rem;
  }

  @media (min-width: ${breakPoints.desktop}) {
    position: absolute !important;
    right: -10rem;
    top: 25rem;
  }
`;

export const StyledContent = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  margin-top: 4rem;
  width: 100%;
  max-width: 75rem;

  @media (min-width: ${breakPoints.tablet}) {
    margin-top: 5rem;
    max-width: 80vw;
  }

  @media (min-width: ${breakPoints.desktop}) {
    margin-top: 7.6rem;
    max-width: 54rem;
  }
`;

export const StyledTitle = styled.h2`
  padding: 0;
  font-size: 2.4rem;
  line-height: 3.6rem;
  font-weight: ${fontWeight.regular};
  color: ${colors.darkBlue};
  margin: 0 0 2rem 0;
  text-align: center;

  @media (min-width: ${breakPoints.tablet}) {
    margin-bottom: 3.1rem;
  }

  @media (min-width: ${breakPoints.desktop}) {
    font-size: 2.8rem;
    line-height: 4.6rem;
  }
`;

export const StyledDescription = styled.p`
  text-align: center;
  font-size: 1.4rem;
  line-height: 3.2rem;
  font-weight: ${fontWeight.light};
  color: ${colors.grayCobalt};
  margin-bottom: 4rem;

  @media (min-width: ${breakPoints.tablet}) {
    max-width: 80vw;
  }

  @media (min-width: ${breakPoints.desktop}) {
    font-size: 1.6rem;
    line-height: 3.8rem;
    max-width: 45rem;
    margin: 0 auto 3.2rem;
  }

  @media (min-width: ${breakPoints.fullsize}) {
    max-width: 51rem;
  }
`;

export const StyledButton = styled(Button)`
  display: block;
  margin: auto;
`;

export const StyledShape1 = styled.div`
  display: none;
  pointer-events: none;

  @media (min-width: ${breakPoints.desktop}) {
    display: inline-block;
    position: absolute;
    height: 50.5rem;
    width: 36.5rem;
    transform: rotate(210deg);
    right: 15rem;
    top: -0.7rem;
  }
`;

export const StyledShape3 = styled.div`
  display: none;
  pointer-events: none;

  @media (min-width: ${breakPoints.desktop}) {
    display: inline-block;
    position: absolute;
    height: 40.4rem;
    width: 52.8rem;
    left: 2rem;
    top: 5rem;
  }

  @media (min-width: ${breakPoints.fullsize}) {
    left: 15rem;
  }
`;
