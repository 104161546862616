import React from 'react';
import Layout from 'templates/Layout/Layout';
import PageHeading from 'components/_shared/PageHeading/PageHeading';
import { Identity, NotJustOffice } from 'components/AboutUs';
import Information from 'components/_shared/Information/Information';

const AboutUs = () => (
  <Layout metaTitle="O nas" metaDescription="Poznajmy się!">
    <PageHeading text="O nas" />
    <Identity />
    <Information
      title="Historia naszego biura"
      description="Biuro Rachunkowe Hossa powstało w 1995 roku wraz z uzyskaniem przez założycielkę biura licencji Ministerstwa Finansów. W pierwszych latach działalności było to kameralne Biuro Rachunkowe. Od samego początku został położony nacisk na jakość prowadzonych usług.  Wraz z upływem lat klienci docenili jakość usług. Rozwój biura polega na poleceniach zadowolonych klientów. Aktualnie została wybudowana nowa siedziba przy <strong>ul. Energetyków 83</strong>.<br /><br />  Naszym mottem jest jakość usług i zadowolenie klientów.  Profesjonalnie obsłużony klient jest naszym najważniejszym wyzwaniem."
      image="aboutUs-header.jpg"
    />
    <NotJustOffice />
  </Layout>
);

export default AboutUs;