import styled from 'styled-components';
import { fontWeight, colors, breakPoints } from 'utils/variables';
import Container from 'components/_shared/Container/Container';

export const StyledWrapper = styled.div`
  position: relative;
`;

export const StyledContainer = styled(Container)`
  position: relative;
  padding: 2rem 3rem 8rem 3rem;
  max-width: 50rem;
  margin: auto;
  width: calc(100% - 6rem);
  height: 8rem;

  @media (min-width: ${breakPoints.tablet}) {
    padding: 6rem 0 0 0;
    max-width: unset;
  }

  @media (min-width: ${breakPoints.desktop}) {
    padding: 0 10rem 18rem 10rem;
  }

  .slider-slide {
    outline: none;
  }
`;

export const StyledTitle = styled.h2`
  padding: 0;
  width: 100%;
  font-size: 3.2rem;
  line-height: 4.8rem;
  font-weight: ${fontWeight.medium};
  color: ${colors.darkBlue};
  display: block;
  text-align: center;
  margin: 0;

  @media (min-width: ${breakPoints.desktop}) {
    margin: 0 0 2.8rem 0;
  }
`;

export const StyledPrev = styled.button`
  background-color: transparent;
  border: 0;
  font-size: 6rem;
  margin-top: 0.7rem;
  font-weight: ${fontWeight.light};
  width: 3.5rem;
  margin-left: -3.5rem;
  cursor: pointer;

  &:hover {
    filter: drop-shadow(0 0 0.5rem ${colors.black});
  }
`;

export const StyledNext = styled.button`
  background-color: transparent;
  border: 0;
  font-size: 6rem;
  margin-top: 0.7rem;
  font-weight: ${fontWeight.light};
  width: 3.5rem;
  margin-right: -3.5rem;
  cursor: pointer;

  &:hover {
    filter: drop-shadow(0 0 0.5rem ${colors.black});
  }
`;
