import styled from 'styled-components';
import Image from 'components/_shared/Image/Image';
import { fontWeight, colors, breakPoints } from 'utils/variables';
import Container from 'components/_shared/Container/Container';

export const StyledWrapper = styled.div`
  position: relative;
`;

export const StyledContainer = styled(Container)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 0 0 6rem 0;

  @media (min-width: ${breakPoints.tablet}) {
    padding: 0 0 2rem 0;
    flex-direction: column;
  }

  @media (min-width: ${breakPoints.desktop}) {
    flex-direction: row;
  }
`;

export const StyledImage = styled(Image)`
  width: 100%;
  height: auto;
  margin-top: 4rem;
  max-width: 50rem;
  max-height: 30rem;

  @media (min-width: ${breakPoints.tablet}) {
    max-width: unset;
    max-height: unset;
    margin-top: 6rem;
    height: 50rem;
  }

  @media (min-width: ${breakPoints.desktop}) {
    margin-top: 4rem;
    width: 62rem;
    height: 47.468rem;
  }

  @media (min-width: ${breakPoints.fullsize}) {
    margin-right: -10rem;
    margin-top: 2.6rem;
    width: 68rem;
    height: 52.2rem;
    max-width: unset;
  }
`;

export const StyledContent = styled.span`
  position: relative;
  margin-top: 3rem;
  width: 100%;
  max-width: 75rem;
  font-size: 1.4rem;
  line-height: 3rem;
  font-weight: ${fontWeight.light};
  color: ${colors.grayCobalt};

  @media (min-width: ${breakPoints.tablet}) {
    margin: 7.5rem 0 0 0;
    max-width: 55.4rem;
  }

  @media (min-width: ${breakPoints.desktop}) {
    margin: 7.5rem 0 0 0;
    font-size: 1.6rem;
    line-height: 3.2rem;
  }
`;
